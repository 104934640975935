<template>
  <div class="w-full">
    <img
      v-if="data.IconImage?.Url"
      :src="data.IconImage.Url"
      data-epi-edit="IconImage"
      class="inline-block mb-8 max-w-1/2 mx-auto"
      :alt="data.IconImage ? data.IconImage.Url : ''"
      width="24"
      height="24"
    />
    <WysiwygWrapper
      :style-config="{ 'wysiwyg wysiwyg--sm block': true }"
      data-epi-edit="mainBody"
      :html="data.MainBody"
    ></WysiwygWrapper>
  </div>
</template>
<script setup lang="ts">
import { type IconItemBlockFragment } from '#gql';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';

const props = defineProps<{
  block: IconItemBlockFragment,
}>();

const data = computed(() => {
  return props.block as IconItemBlockFragment;
});

</script>